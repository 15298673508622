import { BriefcaseIcon, BuildingOffice2Icon } from '@heroicons/react/24/solid';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { useSlideIn } from '@/src/context/slide-in-context';
import SectionHeader from '@/src/widgets/section-header';

import { getTransactions, transactionHeaders } from '../../property/items/transactions/constants';
import IncompleteTransactionsBox from '../../transactions/items/incomplete-transactions-box';
import TransactionTypeDropdown, {
  TransactionType
} from '../../transactions/items/transaction-type-dropdown';

export default function LatestTransactions() {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.ALL);

  const { t } = useTranslation('common');
  const { setSlideIn } = useSlideIn();

  useEffect(() => {
    getTransactions(setTransactions, setLoading, 'get-latest-transactions', null, setSlideIn, t);
  }, []);

  const headers = transactionHeaders?.filter(
    (col) => col.title !== 'general.boa' && col.title !== 'general.loa'
  );

  const gridCss =
    'md:grid grid-cols-10 2xl:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1.3fr_1fr_0.3fr_1.4fr]';

  return (
    <div>
      <div className="pb-4">
        <IncompleteTransactionsBox />
      </div>

      <SectionHeader
        title={t('info.owner-table.latest-transactions')}
        sources={t('info.owner-table.source')}
        helpTextId={'registeredTransactions'}
      />

      {/* Commented out until company transactions are ready */}
      {/* <div className="py-2">
        <TransactionTypeDropdown
          transactionType={transactionType}
          setTransactionType={setTransactionType}
        />
      </div> */}

      <div className="relative max-h-[400px] overflow-y-auto 2xl:pr-4">
        <div className="flex w-full">
          {/* Commented out until company transactions are ready */}
          {/* <div className='w-8' /> */}
          <div
            className={`sticky top-0 bg-white dark:bg-dark-primary z-40 pl-3 justify-start w-full hidden text-xs pt-2 items-start ${gridCss}`}>
            {headers?.map((col, idx) => {
              return (
                <div key={idx} className={`col-span-${col.cols} px-2 font-demi`}>
                  {t(col.title)}
                </div>
              );
            })}
          </div>
        </div>

        {loading ? <LinearProgress className="w-full my-4" /> : <div className="w-full h-2" />}

        <div>
          {transactions && transactions?.length > 0 && !loading ? (
            <>
              <div className="w-full relative flex-col justify-center items-center overflow-hidden overflow-y-auto pb-8">
                {transactions?.map((trans, idx) => {
                  const companyTransaction: boolean = idx % 2 !== 0;

                  return (
                    <div
                      key={idx}
                      className="flex justify-stretch items-center relative w-full hover:bg-bobo-orangelight dark:hover:bg-gray-800 transition-colors group duration-200 odd:bg-gray-50 dark:odd:bg-dark-lessfaded rounded-md leading-3 xl:leading-5 text-mini xl:text-xs py-1 md:py-0 pl-1">
                      {/* Commented out until company transactions are ready */}
                      {/* <div className="h-full absolute w-[1px] bg-bobo-proplight dark:bg-dark-morefaded left-[19px] z-10" /> */}

                      {/* <div className="rounded-full w-8 h-8 flex items-center justify-center mt-3 relative z-20 transition-colors duration-300 bg-gray-100 group-hover:bg-bobo-prop group-hover:text-white text-gray-800 dark:bg-dark-faded dark:group-hover:bg-dark-prophover dark:text-gray-300 has-tooltip">
                        {(companyTransaction || transactionType === TransactionType.REGISTERED) &&
                        transactionType !== TransactionType.COMPANY ? (
                          <BuildingOffice2Icon className="w-4 h-4" />
                        ) : (
                          <BriefcaseIcon className="w-4 h-4" />
                        )}

                        <div className="tooltip bg-white text-bobo-black px-2 py-1 flex items-center dark:bg-dark-primary dark:border-dark-lessfaded dark:text-white shadow border-gray-100 border rounded-md left-8 leading-4">
                          {companyTransaction ? 'Lagfaren transaktion' : 'Bolagstransaktion'}
                        </div>
                      </div> */}

                      <div className={`items-center justify-start w-full pl-2 ${gridCss}`}>
                        {headers?.map((col, idx) => {
                          return (
                            <div
                              key={idx}
                              className={`col-span-${col.cols} p-2 flex md:flex-col justify-between items-start`}>
                              <div className="md:hidden">{t(col.title)}:</div>
                              <div className="w-full text-right md:text-left">
                                {trans[col.data]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="sticky bottom-0 bg-gradient-to-b from-transparent to-white dark:to-dark-primary h-8 xl:h-16 z-50 w-full" />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
